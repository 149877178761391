// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-30-ar-tsx": () => import("./../../../src/pages/30-ar.tsx" /* webpackChunkName: "component---src-pages-30-ar-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-dag-pa-brandbilen-tsx": () => import("./../../../src/pages/en-dag-pa-brandbilen.tsx" /* webpackChunkName: "component---src-pages-en-dag-pa-brandbilen-tsx" */),
  "component---src-pages-foraldrainsats-tsx": () => import("./../../../src/pages/foraldrainsats.tsx" /* webpackChunkName: "component---src-pages-foraldrainsats-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intresseanmalan-index-tsx": () => import("./../../../src/pages/intresseanmalan/index.tsx" /* webpackChunkName: "component---src-pages-intresseanmalan-index-tsx" */),
  "component---src-pages-intresseanmalan-tack-tsx": () => import("./../../../src/pages/intresseanmalan/tack.tsx" /* webpackChunkName: "component---src-pages-intresseanmalan-tack-tsx" */),
  "component---src-pages-jobba-pa-brandbilen-index-tsx": () => import("./../../../src/pages/jobba-pa-brandbilen/index.tsx" /* webpackChunkName: "component---src-pages-jobba-pa-brandbilen-index-tsx" */),
  "component---src-pages-jobba-pa-brandbilen-tack-tsx": () => import("./../../../src/pages/jobba-pa-brandbilen/tack.tsx" /* webpackChunkName: "component---src-pages-jobba-pa-brandbilen-tack-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-kontakt-tack-tsx": () => import("./../../../src/pages/kontakt/tack.tsx" /* webpackChunkName: "component---src-pages-kontakt-tack-tsx" */),
  "component---src-pages-om-brandbilen-tsx": () => import("./../../../src/pages/om-brandbilen.tsx" /* webpackChunkName: "component---src-pages-om-brandbilen-tsx" */),
  "component---src-pages-organisation-tsx": () => import("./../../../src/pages/organisation.tsx" /* webpackChunkName: "component---src-pages-organisation-tsx" */),
  "component---src-pages-ovrig-information-tsx": () => import("./../../../src/pages/ovrig-information.tsx" /* webpackChunkName: "component---src-pages-ovrig-information-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-styrelsen-tsx": () => import("./../../../src/pages/styrelsen.tsx" /* webpackChunkName: "component---src-pages-styrelsen-tsx" */),
  "component---src-pages-synpunkter-tsx": () => import("./../../../src/pages/synpunkter.tsx" /* webpackChunkName: "component---src-pages-synpunkter-tsx" */),
  "component---src-pages-teman-tsx": () => import("./../../../src/pages/teman.tsx" /* webpackChunkName: "component---src-pages-teman-tsx" */),
  "component---src-pages-tyra-tsx": () => import("./../../../src/pages/tyra.tsx" /* webpackChunkName: "component---src-pages-tyra-tsx" */),
  "component---src-pages-vanliga-fragor-tsx": () => import("./../../../src/pages/vanliga-fragor.tsx" /* webpackChunkName: "component---src-pages-vanliga-fragor-tsx" */)
}

